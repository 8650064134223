.popover {
  @include shadow;
  border-color: transparent;
  .manual-arrow {
    position: absolute;
    bottom: -15px;
    font-size: 26px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
  }
}

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
  border-left-color: #f2f2f2;
}

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
  border-bottom-color: #f2f2f2;
}

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
  border-top-color: #f2f2f2;
}

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
  border-right-color: #f2f2f2;
}

.popover {
  .popover-header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 10px;
  }
  .popover-body {
    padding: 15px;
    line-height: 24px;
  }
}